import consumer from "./consumer"

consumer.subscriptions.create("FlashChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    Object.keys(data).forEach(k => {
      [data[k]].flat().forEach(v =>
        notice(k, v)
      )
    })
  }
});
