$(document).on('ready page:change turbo:load turbo:render', function() {
  var selectorElem = $('[data-ean-selector]')[0];
  var selectorInput = $(selectorElem).find('select');

  function fillEanSelector(eans) {
    if (eans.length <= 1) { return $(selectorElem).addClass('d-none'); }
    $(selectorInput).empty();

    var initOption = document.createElement('option');
    initOption.innerText = I18n.t('select');
    initOption.value = "";
    selectorInput.append(initOption);

    eans.forEach(function([display, ean]) {
      option = document.createElement('option');
      option.value = ean;
      option.innerText = display;
      selectorInput.append(option);
    });
    $(selectorElem).removeClass('d-none');
  }

  var initiatorElem = $('[data-ean-customer]')[0];
  var initiatorInput = $(initiatorElem).find('select');

  $(initiatorInput).on('input', function() {
    if (this.value === '') {
      $(selectorElem).addClass('d-none');
      $(selectorInput).empty();
    } else {
      $.ajax('/ean_numbers/' + this.value, {
        success: function(data) { fillEanSelector(data) }
      });
    }
  });
});
