$( document ).on('ready page:change turbo:load turbo:render', function() {
  document.querySelectorAll('[data-show-if], [data-hide-if]').forEach(el => {
    const show = el.getAttribute('data-show-if');
    const hide = el.getAttribute('data-hide-if');

    if (show) {
      const target = document.querySelector('#' + show);
      if (!target) { return; }

      let cnd = function() {
        if (el.getAttribute('data-show-if-in')) {
          let list = JSON.parse(el.getAttribute('data-show-if-in'));
          return !(list.indexOf(+target.value) > -1);
        }

        return !target.checked;
      }

      el.classList.toggle('hidden', cnd())

      $(target).change(() => {
        el.classList.toggle('hidden', cnd())
      })
    }

    if (hide) {
      const target = document.querySelector('#' + hide);
      if (!target) { return; }

      let cnd = function() {
        if (el.getAttribute('data-hide-if-in')) {
          let list = JSON.parse(el.getAttribute('data-hide-if-in'));
          return (list.indexOf(+target.value) > -1);
        }

        return target.checked;
      }

      el.classList.toggle('hidden', cnd())

      $(target).change(() => {
        el.classList.toggle('hidden', cnd())
      })
    }
  })
});

$( document ).on('ready page:change turbo:load turbo:render', function() {
  document.querySelectorAll('[data-if-select]').forEach(el => {
    const sel = document.querySelector(el.getAttribute('data-if-select'))

    if (!sel) { return; }

    ifSelectTrigger(el, sel);
    $(sel).change(() => { ifSelectTrigger(el, sel) });
  });
});

function ifSelectTrigger(el, sel) {
  const checkAttribute = el.getAttribute('data-if-attr')
  const eq = el.getAttribute('data-if-eq') || 'true';

  const option = sel.options[sel.selectedIndex];
  const value = option.getAttribute(checkAttribute)

  if (value == eq) {
    el.classList.remove("d-none")
  } else {
    el.classList.add("d-none")
  }
}
