window.selectSearch = {
  load: function() {
    document.querySelectorAll('[data-search-model]').forEach(el => {
      const model = el.getAttribute('data-search-model')
      const noRemove = el.getAttribute('data-search-removable') == "false"
      const query = el.getAttribute('data-search-query')

      $(el).select2({
        placeholder: {id: '-1', text: I18n.t('search')},
        ajax: {
          delay: 500,
          url: "/search/" + model + "?removable=" + (noRemove ? '0' : '1') + (query.length > 0 ? "&" + query : ""),
          dataType: 'json'
        }
      })

      $(el).on('select2:open', function() {
        const searchEl = document.querySelector('.select2-search__field');
        const index = el.selectedIndex;
        const option = el.options[index];

        if (!option || !option.value) { return; }

        $(searchEl).val(option.textContent).trigger("keyup")
      })
    });
  }
}

$( document ).on('ready page:change turbo:load turbo:render turbo:frame-render', function() {
  selectSearch.load()
});
