$( document ).on('ready page:change turbo:load turbo:render', function() {
  document.querySelectorAll('[data-count-down]').forEach(el => {
    const time = el.getAttribute('data-count-down');

    setInterval(function() {
      const diff = (new Date(time) - new Date()) / 1000;
      const hours = Math.floor(((diff / 60) / 60) % 60);
      const min = Math.floor((diff / 60) % 60);
      const sec = Math.floor(diff % 60);

      if (diff > 1) {
        el.textContent = `${hours}:${min}`;
      } else {
        el.textContent = 'X';
      }
    }, 1000);
  })
});
