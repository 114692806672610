var msg, unsaved;
 
msg = window['I18n'].t('check-modified')

unsaved = false;
 
$(document).on('change', 'form.check-modified:not([data-remote]) :input', function() {
  return unsaved = true;
});

$(document).on('turbo:load turbo:render', function() {
  return unsaved = false;
});
 
$(document).on('submit', 'form.check-modified', function() {
  unsaved = false;
});
 
$(window).bind('beforeunload', function() {
  if (unsaved) {
    return msg;
  }
});
 
$(document).on('turbo:before-visit', function(event) {
  if (unsaved && !confirm(msg)) {
    return event.preventDefault();
  }
});

window.checkModified = function() {
  unsaved = false;
}

window.turboHistoryBack = function() {
  if (unsaved && !confirm(msg)) {
    return;
  } else {
    Turbo.clearCache();
    history.back();
  }
}
