$( document ).on('ready page:change turbo:load turbo:render', function() {
  $('[data-duration-end-date] input, [data-duration-duration] input').on('input change', function() {
    const is_duration = $(this).closest('.col-lg').is("[data-duration-duration]");
    const row = $(this).closest('.row');

    row.find('[data-duration-or]').addClass('d-none').removeClass('d-flex')

    if (is_duration) {
      row.find('[data-duration-end-date]').addClass('d-none')
    } else {
      row.find('[data-duration-duration]').addClass('d-none')
    }
  });
});
