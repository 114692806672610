$( document ).on('ready page:change turbo:load turbo:render', function() {
  function register() {
    $('.custom-file-input').on('change', function() {
      const filelist = Array.from(this.files).map(f => `<li>${f.name}</li>`).join(""); 
      const listNode = $(this).parent().find('.file-list');
      listNode.html(`<ul class="my-auto">${filelist}</ul> <button type="button" class="btn btn-danger my-auto ml-auto btn-icon btn-circle"><i class="fa fa-trash"></i></button> `);
      listNode.removeClass('d-none');
      $(this).parent().addClass('height-auto')

      listNode.find('button').click(function() {
        $(this).parent().parent().remove();
      });

      const filename = Array.from(this.files).map(f => f.name).join(" & "); 
      setTimeout(() => {
        $(this).next('.custom-file-label').html(filename);
      }, 1)
    });

    $('.custom-file-input.file-multi').on('change', function() {
      const form_obj = $(this).parent();
      const new_obj = form_obj.clone();
      const file_list = new_obj.find('.file-list')
      file_list.html('');
      file_list.addClass("d-none");
      new_obj.find('input').val('');
      $(new_obj).insertAfter($(form_obj));

      form_obj.find('.custom-file-label').addClass('hidden');
      form_obj.find('.custom-file-input').addClass('hidden');

      register();
    });
  }

  register();
});
