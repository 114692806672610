$( document ).on('ready page:change turbo:load turbo:render', function() {
  document.querySelectorAll('.datepicker-input').forEach(el => {
    const hlData = $(el).data('highlighted-days');
    const highlightedDays = typeof hlData === 'string' ? hlData.split(',').map(s => s.trim()) : [hlData];
    $(el).datepicker({
      format: 'dd-mm-yyyy',
      calendarWeeks: true,
      clearBtn: true,
      todayHighlight: true,
      daysOfWeekHighlighted: highlightedDays,
      weekStart: 1
    });
  });

  document.querySelectorAll('.datetimepicker-input').forEach(el => {
    $(el).datetimepicker({
      format: 'DD-MM-yyyy HH:mm',
    });
  });
})

$.fn.datepicker.dates['en'] = {
    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    daysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    monthsShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    today: "Today",
    clear: "Clear",
    format: "dd-mm-yyyy",
    titleFormat: "MM yyyy",
    weekStart: 0
};
