require('./count-down.js');
require('./notice.js');
require('./turbo-frame.js');

$( document ).on('ready page:change turbo:load turbo:render', function() {
  window['KTLayoutQuickUser'].init('kt_quick_user');
  window['KTLayoutQuickPanel'].init('kt_quick_panel');
  KTApp.init(KTAppSettings);
});

$( document ).on('ready page:change turbo:load turbo:render turbo:frame-render', function() {
  $('body > .tooltip').remove();
})
