import Rails from '@rails/ujs';

Rails.confirm = function(message, element) {
  Swal.fire({
    title: message,
    text: '', //I18n.t('you_wont_be_able_to_undo_this'),
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: I18n.t('yes_im_sure'),
    cancelButtonText: I18n.t('cancel')
  }).then(function(result) {
    if (result.value) {
      element.removeAttribute("data-confirm");
      element.click();
    }
  })
}


const ICONS = {
  'alert': 'flaticon-warning',
  'notice': 'flaticon-like',
  'info': 'flaticon2-notification'
}

const ANIMATIONS_IN = {
  'alert': 'wobble',
  'notice': 'fadeInDown',
  'info': 'fadeInDown'
}

const ANIMATIONS_OUT = {
  'alert': 'fadeOutDown',
  'notice': 'fadeOutDown',
  'info': 'fadeOutDown'
}

const TYPES = {
  'alert': 'danger',
  'notice': 'success',
  'info': 'info'
}

window.notice = function(type, msg) {
  const icon = 
    $.notify(
      {
        icon: ICONS[type],
        message: '',
        title: msg,
      },
      {
        type: TYPES[type],
        timer: 7000,
        placement: {
          from: "bottom",
          align: "left"
        },
        offset: {
          x: document.querySelector('#kt_content').offsetLeft,
          y: 50
        },
        animate: {
          enter: 'animate__animated animate__' + ANIMATIONS_IN[type],
          exit: 'animate__animated animate__' + ANIMATIONS_OUT[type],
        },
      }
    )
}
