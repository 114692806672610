$( document ).on('ready page:change turbo:load turbo:render', function() {
  document.querySelectorAll('[data-form-clone]').forEach(el =>
    el.onclick = () => {
      const target = el.getAttribute('data-form-clone')
      const node = document.querySelector('[data-' + target + ']');
      const clone = node.cloneNode(true);
      clone.removeAttribute('data-' + target);

      const next = +el.getAttribute('data-form-next');

      clone.querySelectorAll('input').forEach(input => {
        input.name = input.name.replace('[0]', '[' + next + ']')
        input.id = input.id.replace('_0_', '_' + next + '_')
        input.value = null;
      }); 

      clone.querySelectorAll('label').forEach(label => {
        label.setAttribute('for', label.getAttribute('for').replace('_0_', '_' + next + '_'))
      });

      el.setAttribute('data-form-next', next + 1);
      node.parentNode.prepend(clone);
    }
  )
})
