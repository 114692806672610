window.subheaderSubmit = {
  load: function() {
    document.querySelectorAll('[data-submit]').forEach(el => {
      el.onclick = function() {
        const cls = el.getAttribute('data-submit');
        const form = document.querySelector( '.' + cls )

        $(form).on('turbo:submit-start', ({target}) => {
          if (target.classList.contains(cls)) {
            el.disabled = true;
            el.querySelector("i").classList.remove("fa-save")
            el.querySelector("i").classList.add("fa-cog")
            el.querySelector("i").classList.add("fa-spin")
          }
        })

        $(form).on('turbo:submit-end', ({target}) => {
          if (target.classList.contains(cls)) {
            el.disabled = false;
            el.querySelector("i").classList.add("fa-save")
            el.querySelector("i").classList.remove("fa-cog")
            el.querySelector("i").classList.remove("fa-spin")
          }
        })

        window.checkModified();

        if (form.getAttribute('data-remote') == 'true') { 
          $.rails.fire(form, 'submit')
        }
        else
        {
          form.requestSubmit();
        }
      }
    });
  }
}

$( document ).on('ready page:change turbo:load turbo:render turbo:frame-render', function() {
  subheaderSubmit.load()
});
